@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 1px white;
  }
  .font-outline-4 {
    -webkit-text-stroke: 4px white;
  }
}
@layer utilities {
  input,
  select,
  textarea {
    border: none !important;
    border-bottom: 1px solid #fff !important;
    background-color: transparent !important;
  }
  input:focus {
    outline: none !important;
    outline-offset: 0px;
    --tw-ring-inset: 0;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: transparent !important;
  }
  input[type='submit'] {
    text-decoration: none !important;
    border-bottom: none !important;
    background-color: #9788f0 !important;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
  }
  input[type='submit']:disabled {
    background-color: #ccc !important;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}
html {
  overflow-x: hidden;
}
table {
  overflow-x: scroll;
}
body {
  margin: 0;
  overflow-x: hidden;
  background-color: #000;
  color: #fff;
  font-family: 'Plus Jakarta Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.blinking-cursor::after {
  content: '|';
  animation: blink 1s step-end infinite;
}
.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  filter: grayscale(1);
  opacity: 0.3;
}
.hoverImg {
  filter: brightness(0) saturate(100%) invert(100%) sepia(67%) saturate(2247%) hue-rotate(13deg)
    brightness(102%) contrast(91%);
}

::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 0.5em;
  background: #000;
}

::-webkit-scrollbar-track {
  /* Customize the scrollbar track */
  background-color: #323232;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background: #525252;
  /* Customize the scrollbar thumb */
}
